import React from "react";
import styled from "styled-components/macro";
import { observer } from "mobx-react";
import { ECMO, basalCondition, ECMOshunt } from "./state";

const Header = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin-bottom: 1em;
  }
  & > :not(:last-child) {
    margin-right: 1em;
  }
`;

const InfoBox = styled.div`
  padding: 0.5em;
  background-color: rgb(249, 255, 215);
  width: 160px;
  & > * {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

const Title = styled.div`
  font-size: 1.2em;
  color: rgb(174, 39, 39);
  margin-bottom: 0.2em;
`;

const O2 = (
  <span style={{ marginLeft: "0.2em" }}>
    O<sub>2</sub>
  </span>
);

const SectionInputs = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
`;

const Section = styled.section`
  margin: auto 2em;
  max-width: 1000px;
  position: relative;
  z-index: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & label {
    font-size: 0.8em;
  }
  & > :not(:last-child) {
    margin-bottom: 0.2em;
  }
`;

const shuntMap = new Map([
  [0.1, "10"],
  [0.2, "20"],
  [0.3, "30"],
]);

const ShuntSelect = ({
  id,
  children,
  value,
  onChange,
}: {
  id: string;
  children: React.ReactNode;
  value: number;
  onChange: (n: number) => void;
}) => {
  return (
    <InputWrapper>
      <label htmlFor={id}>{children}</label>
      <select
        id={id}
        value={shuntMap.get(value)}
        onChange={(e) => onChange(parseFloat(e.target.value) / 100)}
      >
        <option value="10">10%</option>
        <option value="20">20%</option>
        <option value="30">30%</option>
      </select>
    </InputWrapper>
  );
};

const BasicInput = ({
  id,
  children,
  value,
  onChange,
}: {
  id: string;
  children: React.ReactNode;
  value: number;
  onChange: (n: number) => void;
}) => {
  return (
    <InputWrapper>
      <label htmlFor={id}>{children}</label>
      <input
        id={id}
        type="number"
        value={value}
        onChange={(e) => onChange(parseFloat(e.target.value))}
      />
    </InputWrapper>
  );
};

function App() {
  return (
    <div className="App">
      <Header>
        <InfoBox>
          <Title>VV-ECMO</Title>
          <div>
            {Math.round(ECMO.SaO2ECMO * 100) / 100} sat {O2}
          </div>
          <div>
            {Math.round(ECMO.PaO2ECMO)} pressure {O2}
          </div>
        </InfoBox>
        <InfoBox>
          <Title>VV-ECMO shunt</Title>
          <div>
            {Math.round(ECMOshunt.SaO2ECMO * 100) / 100} sat {O2}
          </div>
          <div>
            {Math.round(ECMOshunt.PaO2ECMO)} pressure {O2}
          </div>
        </InfoBox>
      </Header>
      <Section>
        <h4>VV-ECMO</h4>
        <SectionInputs>
          <BasicInput id="FSO2" value={ECMO.FSO2} onChange={ECMO.setFSO2}>
            F<sub>S</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput id="QEC" value={ECMO.QEC} onChange={ECMO.setQEC}>
            Q<sub>EC</sub>
          </BasicInput>
          <BasicInput id="PECO2" value={ECMO.PECO2} onChange={ECMO.setPECO2}>
            P<sub>EC</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput id="SECO2" value={ECMO.SECO2} onChange={ECMO.setSECO2}>
            S<sub>EC</sub>O<sub>2</sub>
          </BasicInput>
          <ShuntSelect
            id="shunt"
            value={ECMOshunt.shuntLung}
            onChange={ECMOshunt.updateShunt}
          >
            Lung Shunt
          </ShuntSelect>
        </SectionInputs>
      </Section>

      <Section>
        <h4>Basal Condition</h4>
        <SectionInputs>
          <BasicInput
            id="PVO2"
            value={basalCondition.PVO2}
            onChange={basalCondition.setPVO2}
          >
            P<sub>V</sub>O<sub>2</sub>
          </BasicInput>

          <BasicInput
            id="PaO2"
            value={basalCondition.PaO2}
            onChange={basalCondition.setPaO2}
          >
            P<sub>a</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput
            id="PaCO2"
            value={basalCondition.PaCO2}
            onChange={basalCondition.setPaCO2}
          >
            P<sub>a</sub>CO<sub>2</sub>
          </BasicInput>
          <BasicInput
            id="SaO2"
            value={basalCondition.SaO2}
            onChange={basalCondition.setSaO2}
          >
            S<sub>a</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput
            id="SVO2"
            value={basalCondition.SVO2}
            onChange={basalCondition.setSVO2}
          >
            S<sub>V</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput
            id="Hb"
            value={basalCondition.Hb}
            onChange={basalCondition.setHb}
          >
            H<sub>b</sub>
          </BasicInput>
          <BasicInput
            id="FiO2"
            value={basalCondition.FiO2}
            onChange={basalCondition.setFiO2}
          >
            F<sub>I</sub>O<sub>2</sub>
          </BasicInput>
          <BasicInput
            id="Pb"
            value={basalCondition.Pb}
            onChange={basalCondition.setPb}
          >
            P<sub>b</sub>
          </BasicInput>
          <BasicInput
            id="PH2O"
            value={basalCondition.PH2O}
            onChange={basalCondition.setPH2O}
          >
            P
            <sub>
              H<sub>2</sub>O
            </sub>
          </BasicInput>
        </SectionInputs>
      </Section>
    </div>
  );
}

export default observer(App);
