import { types } from "mobx-state-tree";

const solubility = 0.031;
const O2Sat = 1.39;

function nthRoot(x: number, n: number) {
  if (x < 0 && n % 2 !== 1) return NaN; // Not well defined
  return (x < 0 ? -1 : 1) * Math.pow(Math.abs(x), 1 / n);
}

const inverseSeveringhaus = (s: number) => {
  const h = Math.sqrt(500000);
  const yn = ((-23400 / h) * s) / (1 - s);
  const a = -yn + Math.sqrt(Math.pow(yn, 2) + 1);
  const b = -yn - Math.sqrt(Math.pow(yn, 2) + 1);

  return nthRoot(0.5 * h * a, 3) + nthRoot(0.5 * h * b, 3);
};

const BasalCondition = types
  .model({
    PVO2: types.number,
    PaO2: types.number,
    PaCO2: types.number,
    SaO2: types.number,
    SVO2: types.number,
    Hb: types.number,
    FiO2: types.number,
    Pb: types.number,
    RQ: types.number,
    PH2O: types.number,
  })
  .views((self) => ({
    get PAO2() {
      return (self.Pb - self.PH2O) * self.FiO2 - self.PaCO2 / self.RQ;
    },
  }))
  .views((self) => ({
    get PC02() {
      return self.PAO2;
    },
    get SC02() {
      return 1;
    },
  }))
  .views((self) => ({
    get CCO2() {
      return self.Hb * self.SC02 * O2Sat + self.PC02 * solubility;
    },
    get CaO2() {
      return self.Hb * self.SaO2 * O2Sat + self.PaO2 * solubility;
    },
    get CVO2() {
      return self.Hb * self.SVO2 * O2Sat + self.PVO2 * solubility;
    },
  }))
  .views((self) => ({
    get FShuntTotal() {
      return (self.CCO2 - self.CaO2) / (self.CCO2 - self.CVO2);
    },
  }))
  .actions((self) => ({
    setPVO2(n: number) {
      self.PVO2 = n;
    },
    setPaO2(n: number) {
      self.PaO2 = n;
    },
    setPaCO2(n: number) {
      self.PaCO2 = n;
    },
    setSaO2(n: number) {
      self.SaO2 = n;
    },
    setSVO2(n: number) {
      self.SVO2 = n;
    },
    setHb(n: number) {
      self.Hb = n;
    },
    setFiO2(n: number) {
      self.FiO2 = n;
    },
    setPb(n: number) {
      self.Pb = n;
    },
    setRQ(n: number) {
      self.RQ = n;
    },
    setPH2O(n: number) {
      self.PH2O = n;
    },
  }));

const UnderECMO = types
  .model({
    basalCondition: BasalCondition,
    FSO2: types.number,
    QEC: types.number,
    PECO2: types.number,
    SECO2: types.number,
    QT: types.number,
  })
  .views((self) => ({
    get CECO2() {
      return (
        self.basalCondition.Hb * self.SECO2 * O2Sat + self.PECO2 * solubility
      );
    },
  }))
  .views((self) => ({
    get CRVO2() {
      return (
        (self.QEC / self.QT) * self.CECO2 +
        (1 - self.QEC / self.QT) * self.basalCondition.CVO2
      );
    },
  }))
  .views((self) => ({
    get CaO2ECMO() {
      return (
        self.basalCondition.FShuntTotal * self.CRVO2 +
        (1 - self.basalCondition.FShuntTotal) * self.basalCondition.CCO2
      );
    },
  }))
  .views((self) => ({
    get SaO2ECMO() {
      return (
        (self.CaO2ECMO - solubility * self.basalCondition.PaO2) /
        (self.basalCondition.Hb * O2Sat)
      );
    },
  }))
  .views((self) => ({
    get PaO2ECMO() {
      return inverseSeveringhaus(self.SaO2ECMO);
    },
  }))
  .actions((self) => ({
    setFSO2(n: number) {
      self.FSO2 = n;
    },
    setQEC(n: number) {
      self.QEC = n;
    },
    setPECO2(n: number) {
      self.PECO2 = n;
    },
    setSECO2(n: number) {
      self.SECO2 = n;
    },
    setQT(n: number) {
      self.QT = n;
    },
  }));

const UnderECMOWithShunt = types
  .model({
    ECMO: UnderECMO,
    shuntLung: types.number,
  })
  .views((self) => ({
    get FShuntHeart() {
      return self.ECMO.basalCondition.FShuntTotal - self.shuntLung;
    },
  }))
  .views((self) => ({
    get CLAO2() {
      return (
        self.ECMO.basalCondition.CCO2 -
        self.shuntLung * (self.ECMO.basalCondition.CCO2 - self.ECMO.CRVO2)
      );
    },
  }))
  .views((self) => ({
    get CaO2ECMO() {
      return (
        self.FShuntHeart * self.ECMO.CRVO2 + (1 - self.FShuntHeart) * self.CLAO2
      );
    },
  }))
  .views((self) => ({
    get SaO2ECMO() {
      return (
        (self.CaO2ECMO - solubility * self.ECMO.basalCondition.PaO2) /
        (self.ECMO.basalCondition.Hb * O2Sat)
      );
    },
  }))
  .views((self) => ({
    get PaO2ECMO() {
      return inverseSeveringhaus(self.SaO2ECMO);
    },
  }))
  .actions((self) => ({
    updateShunt(s: number) {
      self.shuntLung = s;
    },
  }));

const initialSnapshotBasal = {
  PVO2: 30,
  PaO2: 37,
  PaCO2: 40,
  SaO2: 0.72,
  SVO2: 0.56,
  Hb: 151,
  FiO2: 1,
  Pb: 710,
  RQ: 0.8,
  PH2O: 47,
};

const initialSnapshotECMO = {
  basalCondition: initialSnapshotBasal,
  FSO2: 0.8,
  QEC: 4.5,
  PECO2: 400,
  SECO2: 1,
  QT: 7,
};

export const basalCondition = BasalCondition.create(initialSnapshotBasal);
export const ECMO = UnderECMO.create({
  ...initialSnapshotECMO,
  basalCondition,
});
export const ECMOshunt = UnderECMOWithShunt.create({
  ECMO,
  shuntLung: 0.1,
});
